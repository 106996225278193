.styled_button {
  background-color: #000000;
  min-height: 4rem;
  min-width: 12rem;
  border: solid 4px #3c92b1;
  border-radius: 100px;
  color: white;
  cursor: pointer;
  @media (max-width: 812px) {
    min-width: 100%;
  }
}



.styled_button:disabled,
.styled_button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.styled_button a {
  color: white;
}


