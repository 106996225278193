.layout{
  position:absolute;
  top:300px;
  width:100%;
  height:100px;
}

#benefits{
  position:relative;
  /* set div center*/
  margin: 0 auto;
  padding-left: 25%;
  /* width: 50rem; */
  /* alpha color */
  background-color: rgba(50, 38, 1, 0.848);
  /* align right */
  text-align: left;

}

.nav-logo{
  width: 160px;
}
.navbarHome {
  background-color: #752EDC !important;
  border-radius: 100px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}


.navlinkNormal {
  font-size: 16px !important;
  margin-left: 10px;
  font-weight: 900 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 100px;
  /* white-space: nowrap; */
}

.navlinkNormal:hover {
  background-color: #025159 !important;
  border-radius: 1em;
}

.navlinkDoc {
  font-style: normal;
  font-weight: 700 !important;
}
.navbar-brand{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 24px !important;
  font-family: "Inter", Helvetica !important;
}
.navbar{
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}




/* contact section styles */
.background {
  height: 90vh;
  background-image: url("./assets/images/sea_background.jpg");
  background-size: cover;
  padding: 4rem 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
}
.contactContainer {
  width: 90%;
  backdrop-filter: blur(10px);
  box-shadow: 0px -4px 16px 8px rgba(60, 146, 177, 0.1),
    0px 4px 16px 8px rgba(174, 174, 174, 0.25);
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  padding: 1rem;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;
}
.contactInformation {
  background: #050f13;
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
  border-radius: 20px;
  padding: 2rem;
}
.contactForm {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
}
.formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.formInput label {
  font-size: 0.75rem;
  margin-bottom: 0rem;
  color: var(--Background);
}
.formInput input {
  outline: none;
  padding: 1rem;
  border: none;
  background: transparent;
  border-bottom: solid 2px var(--Background);
  font-size: 1.125rem;
  color: black;
  font-family: "Lato";
}
.formInput textarea {
  outline: none;
  padding: 1rem;
  border: none;
  background: transparent;
  border-bottom: solid 2px var(--Background);
  font-size: 1.125rem;
  color: black;
  font-family: "Lato";
}

@media only screen and (max-width: 414px) {
  /* contact section styles */
  .background {
    padding: 4rem 1rem;
  }
  .contactContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* Note: backdrop-filter has minimal browser support */
  }
  .contactInformation {
    padding: 1rem;
    width: 100%;
  }
  .contactForm {
    padding: 2rem 1rem;
  }
  .formInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .formInput label {
    font-size: 0.75rem;
    margin-bottom: 0rem;
    color: var(--Background);
  }
  .formInput input {
    outline: none;
    padding: 1rem;
    border: none;
    background: transparent;
    border-bottom: solid 2px var(--Background);
    font-size: 1.125rem;
    color: black;
    font-family: "Lato";
  }
  .formInput textarea {
    outline: none;
    padding: 1rem;
    border: none;
    background: transparent;
    border-bottom: solid 2px var(--Background);
    font-size: 1.125rem;
    color: black;
    font-family: "Lato";
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
